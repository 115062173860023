import React from "react";
import clientPromise from "../lib/mddb.mjs";
import computeFields from "../lib/computeFields";
import UnstyledLayout from "@/layouts/unstyled";
import Index from "components/home/Index"
import fs from "fs"

export default function IndexPage({
  blogs
}: any) {
  return (
    <UnstyledLayout>
      <Index casestudies={blogs} />
    </UnstyledLayout>
  );
}

export async function getStaticProps() {
  const mddb = await clientPromise;
  const blogFiles = await mddb.getFiles({ folder: "showcase/case-studies" });
  const blogsMetadataPromises = blogFiles.map(async (b) => {
    const source = fs.readFileSync(b.file_path, { encoding: "utf-8" });

    // TODO temporary replacement for contentlayer's computedFields
    const frontMatterWithComputedFields = await computeFields({
      frontMatter: b.metadata,
      urlPath: b.url_path,
      filePath: b.file_path,
      source,
    });
    return frontMatterWithComputedFields;
  });

  const blogsList = await Promise.all(blogsMetadataPromises);

  return {
    props: {
      meta: {
        title: "Datopian - Leading CKAN and Data Experts",
        description: "Datopian are experts in data management and work with organizations of all sizes to design, develop and scale solutions to unlock their data's potential."
      },
      blogs: blogsList,
      image: "https://www.datopian.com/assets/images/layout/datopian_logo.webp"
    },
  };
};
